import { useEffect } from 'react';

import styles from './ArticleBarrier.module.scss';

export const ArticleBarrier = () => {
  useEffect(() => {
    document.body.classList.add('blocker-active');

    return () => {
      document.body.classList.remove('blocker-active');
    };
  }, []);

  return (
    <>
      <div className={styles.pianoBottom} id="pianoBottom" />

      <div className={styles.blocker} id="blocker" />
    </>
  );
};
