import { On3IconLockPremium } from '@on3/icons';
import { useAuth } from '@on3/ui-lib/index';
import { webUrl } from '@on3/ui-lib/utils/api';
import clsx from 'clsx';
import { Link } from 'components/Link';

import styles from './PremiumBlock.module.scss';

interface PremiumBlockProps {
  className?: string;
  title?: string;
  linkText?: string;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;
}

export const PremiumBlock = ({
  className,
  title,
  linkText,
  utmSource,
  utmMedium,
  utmCampaign,
  utmContent,
}: PremiumBlockProps) => {
  const { user } = useAuth();
  const utmUrl = new URL('/subscribe/', webUrl);

  if (utmSource) utmUrl.searchParams.append('utm_source', utmSource);
  if (utmMedium) utmUrl.searchParams.append('utm_medium', utmMedium);
  if (utmCampaign) utmUrl.searchParams.append('utm_campaign', utmCampaign);
  if (utmContent) utmUrl.searchParams.append('utm_content', utmContent);

  return (
    <Link
      className={clsx(styles.joinBlock, className)}
      href={user?.has ? null : String(utmUrl)}
    >
      <div>
        <On3IconLockPremium />
        <span className={styles.joinButton}>Members Only</span>
      </div>
      <div>
        {title || 'To unlock,'}
        {!user?.has && (
          <span className={styles.joinLink}>{linkText || 'Subscribe Now'}</span>
        )}
      </div>
    </Link>
  );
};
