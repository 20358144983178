import { useAuth } from '@on3/ui-lib/src/contexts/AuthProvider';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { connatixPlayerData } from './Connatix.data';
import styles from './ConnatixPlayer.module.scss';

declare global {
  interface Window {
    orion: any;
    cnx: any;
  }
}

const defaultOn3Player = {
  id: '05afbb67ef5847dea0ab6970e2a5b1da',
  token: '6e54b5b3-3abd-4e32-89dc-cf42dc9a3323',
};

export const ConnatixPlayer = () => {
  const { user } = useAuth();
  const { currentSite } = useSite();
  const router = useRouter();
  const { sites } = connatixPlayerData;
  const isRecruits = currentSite.key === 409;

  const {
    id = '5d4a5d87696740b69a9697e85a28e21b',
    token = 'fdf64619-f0f7-4020-a8a4-808666d89037',
  } =
    sites.find(
      (site) => currentSite?.key && site.sites.includes(currentSite?.key),
    ) || defaultOn3Player;

  if (user?.st?.includes('premium')) {
    return null;
  }

  if (!id || !token || isRecruits) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const startConnatixPlayer = () => {
      if (window.cnx && typeof window.cnx === 'object') {
        window.cnx.cmd.push(function () {
          window
            .cnx({ playerId: token })
            .render(id, function (err: unknown, api: any) {
              if (!err) {
                api.on('adImpression', function () {
                  window.orion?.reportConnatixPlayerAdImpression({
                    playerId: token,
                  });
                });
              } else {
                console.error('Error initializing Connatix player:', err);
              }

              if (user?.has) {
                api.disableFloatingMode();
              }
            });
        });
      }
    };

    if (!document.getElementById('connatix-script')) {
      const script = document.createElement('script');

      script.id = 'connatix-script';
      script.src = `//cd.connatix.com/connatix.player.js?cid=d5c08bb0-aac7-47ee-a414-99e239c45d9d&pid=2f58926f-6176-4d31-ae89-0fcba39bcb43`;
      script.async = true;
      script.onload = startConnatixPlayer;
      document.head.appendChild(script);
    } else {
      startConnatixPlayer();
    }

    const handleRouteChange = () => {
      startConnatixPlayer();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, token, id]);

  return (
    <div className={styles.container}>
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <script>
              (new Image()).src = "https://capi.connatix.com/tr/si?token=${token}&cid=d5c08bb0-aac7-47ee-a414-99e239c45d9d";
            </script>
          `,
        }}
        id={id}
      />
    </div>
  );
};

export default ConnatixPlayer;
