import { SVGProps } from 'react';

const SvgPremiumPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 48 18"
    fill="none"
    {...props}
  >
    <g clipPath="url(#premium-plus_svg__a)">
      <path
        d="M11.79 11.85c-.2.65-.499 1.2-.948 1.65-.45.45-.95.8-1.65 1.05-.649.25-1.448.4-2.347.4h-1.6c-.898 0-1.698-.15-2.397-.4-.65-.25-1.2-.6-1.649-1.05-.45-.45-.7-1-.9-1.65C.1 11.2 0 10.5 0 9.7V7.8c0-.8.1-1.5.3-2.15.2-.65.5-1.2.949-1.65.4-.5.95-.85 1.649-1.1.65-.25 1.449-.35 2.398-.35h1.599c.899 0 1.698.1 2.348.35.65.25 1.199.6 1.649 1.05.45.45.749 1 .949 1.65.2.65.3 1.35.3 2.15v1.9c0 .85-.1 1.55-.35 2.2Zm-9.092.65c.45.55 1.249.8 2.348.8h2.098c1.1 0 1.899-.25 2.349-.8.45-.55.699-1.35.699-2.4V7.3c0-1.1-.25-1.9-.7-2.4-.45-.55-1.248-.8-2.348-.8H5.046c-1.1 0-1.849.25-2.348.8-.45.55-.7 1.35-.7 2.45v2.8c0 1.05.25 1.85.7 2.35ZM22.732 15.15c-.5 0-.95-.15-1.249-.4-.3-.25-.6-.6-.85-1l-4.096-6.6c-.1-.15-.15-.25-.2-.3 0-.05-.05-.05-.1-.05-.1 0-.1.05-.1.05-.05.05-.05.15-.05.25v7.95H14.24V7.1c0-.6.15-1.1.45-1.5.3-.45.799-.65 1.498-.65.5 0 .9.15 1.2.45.3.25.599.65.899 1.15l4.046 6.5c.05.1.15.2.2.25.05.05.05.05.1.05s.05 0 .1-.05.05-.1.05-.2v-8h1.849v7.95c0 .6-.15 1.1-.45 1.45-.4.45-.85.65-1.449.65ZM35.672 17.5h-5.496c-1.798 0-3.297-1.5-3.297-3.3V3.8c0-1.8 1.499-3.3 3.297-3.3h5.496c1.849 0 3.297 1.5 3.297 3.3v10.45c0 1.8-1.448 3.25-3.297 3.25Z"
        fill="#29f085"
      />
      <path
        d="M33.274 15.15h-3.947V12.7h4.097c.4-.05.7-.35.75-.7v-.2c0-.95-1.7-1.6-2.449-2.25V8.5c.7-.65 2.448-1.35 2.448-2.25v-.2c-.05-.35-.35-.65-.75-.7h-4.146v-2.4h4.197c1.449.1 2.698 1.15 2.998 2.55.05.2.05.4.05.65 0 1.05-.55 2.05-1.45 2.65l-.3.25.3.25c.9.6 1.45 1.6 1.45 2.65 0 .2 0 .45-.05.65-.3 1.4-1.5 2.45-2.998 2.55h-.2Z"
        fill="#fff"
      />
      <path d="M45.065 5.35h-1.799v7.3h1.799v-7.3Z" fill="#29f085" />
      <path d="M40.511 8.114v1.8h7.294v-1.8h-7.294Z" fill="#29f085" />
    </g>
    <defs>
      <clipPath id="premium-plus_svg__a">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h47.813v17H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgPremiumPlus;
